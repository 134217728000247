import { Component } from '@angular/core';
import * as $ from 'jquery';

@Component({
	selector:'app-header',
	templateUrl:'header.component.html',
	styleUrls:['header.component.scss']
})
export class HeaderComponent{
	constructor(){
	}
	public ngOnInit(){
		function toggleNav() {
			$('.navIcon').click(function() {
			  $(this).toggleClass('opened');
			  $('.navContainer').toggleClass('opened');
			  if($(this).hasClass('opened')){
				  console.log('tem a classe');
				$('.lk-menu').click(function() {
					console.log('feshow');
					$('.navIcon').removeClass('opened');
					$('.navContainer').removeClass('opened');

				});
			  }
			});
		  }
		  
		  toggleNav();
	}
	 
}