import { Component } from '@angular/core';
import { FormsModule, FormControl, Validators } from '@angular/forms';
import { FormService } from './form.service';

export class ContactForm{
	name: string;
	email: string;
	phoneNumber: string;
	message: string;
}

@Component({
	selector:'app-footer',
	templateUrl:'footer.component.html',
	styleUrls:['footer.component.scss']
})
export class FooterComponent{
	form : ContactForm = new ContactForm;
	sendingMessage: boolean = false;
	formSubmited: boolean = false;

	constructor( private formService : FormService){
	}
	email = new FormControl('', [Validators.required, Validators.email]);
	
	sendForm(){
		this.sendingMessage = true;
		this.formService.sendLead(this.form).then( response => {
			console.log(response);
			this.sendingMessage = false;
			this.formSubmited = true;
			this.form = new ContactForm;
			this.email = new FormControl('', [Validators.required, Validators.email]);
		});
	}

	getErrorMessage() {
		return this.email.hasError('required') ? 'You must enter a value' :
			this.email.hasError('email') ? 'Not a valid email' :
				'';
	}
}