import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactForm } from './footer.component';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  endpoint: string = "https://apis.horizonsolutions.com.br/open/sendLeadForm/";
  templateId: string = "d-679ae3834a06456dba59bf7d05ffda05"; 

  constructor(private http: HttpClient) { }


  sendLead(form: ContactForm) : Promise<ContactForm> {
    return this.http.post(this.endpoint + this.templateId, form)
            .toPromise()
            .then(response => response as ContactForm)
            .catch();
  }


}
